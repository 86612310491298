var Handlebars = require("../../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='pay-keeper-popup__content js-pay-keeper-popup__content'>\n  <button\n    type='button'\n    class='pay-keeper-popup__close js-pay-keeper-popup__close'\n    aria-label='Закрыть попап оплаты через пейкипер'\n  ></button>\n  <h3 class='pay-keeper-popup__header'>"
    + ((stack1 = __default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.header",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":39},"end":{"line":7,"column":73}}})) != null ? stack1 : "")
    + "</h3>\n  <form\n    class='pay-keeper-popup__form js-pay-keeper-popup__form'\n    method='POST'\n    action='"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"paykeeper_url") : stack1), depth0))
    + "/create'\n    target='_blank'\n  >\n    <div class='pay-keeper-popup__info'>\n      <label class='pay-keeper-popup__info-item'>\n        "
    + alias3(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.orderId",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":16,"column":41}}}))
    + "\n        <input type='text' name='orderid' value='"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"order_id") : stack1), depth0))
    + "' class='pay-keeper-popup__order-id' readonly>\n      </label>\n      <label class='pay-keeper-popup__info-item'>\n        "
    + alias3(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.price",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":39}}}))
    + "\n        <span class='pay-keeper-popup__price'>"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"formatted_total_price") : stack1), depth0))
    + "</span>\n        <input type='text' name='sum' value='"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"total_price") : stack1), depth0))
    + "' hidden>\n        <input type=\"text\" name=\"service_name\" value=\""
    + alias3(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.serviceName",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":54},"end":{"line":23,"column":91}}}))
    + " "
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"company_name") : stack1), depth0))
    + "\" hidden>\n        <input type='text' name='cart' value='"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"cart") : stack1), depth0))
    + "' hidden>\n      </label>\n      <input\n        type='text'\n        name='service_name'\n        value='"
    + alias3(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.serviceName",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":29,"column":15},"end":{"line":29,"column":52}}}))
    + "'\n        hidden\n      />\n    </div>\n    <label class='online-payment-popup__form-label'>\n      "
    + alias3(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.userName",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":34,"column":6},"end":{"line":34,"column":40}}}))
    + "\n      <input\n        type='text'\n        class='pay-keeper-popup__form-input'\n        name='clientid'\n        value='"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"customer_name") : stack1), depth0))
    + "'\n        maxlength=200\n      />\n    </label>\n    <label class='pay-keeper-popup__form-label'>\n      "
    + alias3(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.userEmail",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":44,"column":6},"end":{"line":44,"column":41}}}))
    + "\n      <input\n        type='text'\n        class='pay-keeper-popup__form-input'\n        name='client_email'\n        value='"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"customer_email") : stack1), depth0))
    + "'\n        maxlength=200\n        pattern='^.+@.+\\..+$'\n      />\n    </label>\n    <label class='pay-keeper-popup__form-label'>\n      "
    + alias3(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.userPhone",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":55,"column":6},"end":{"line":55,"column":41}}}))
    + "*\n      <input\n        type='text'\n        class='pay-keeper-popup__form-input js-pay-keeper-popup__phone-input'\n        name='client_phone'\n        value='"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"customer_phone") : stack1), depth0))
    + "'\n        pattern='^((8|\\+7)[\\-\\s]?)?(\\(?\\d{3}\\)?[\\-\\s]?)?[\\d\\-\\s]{7,10}$'\n        required\n      />\n    </label>\n    <button\n      type='button'\n      class='pay-keeper-popup__form-submit js-pay-keeper-popup__form-submit'\n    >\n      "
    + alias3(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.submitText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":69,"column":6},"end":{"line":69,"column":42}}}))
    + "\n    </button>\n  </form>\n</div>\n";
},"useData":true});