/**
 * Модуль работы с АК и контактами пользователя на попапах заказа
 * В app.config.orderForm.config находится конфиг попапа по его типу из файла config/templates/order_forms/config.yml
 * Настройки полей АК задаются в конфиге в большинстве случаев. (свойства fieldset и required_fields)
 * Для переопределения вывода полей с учетом настроек способов связи компании необходимо
 * в конфиге указать custom_authorization: true.
 * Важно! При указании этого флага поля конфига fieldset и required_fields перестают влиять на АК.
 */

import {getOrderSettings} from '@apress/gems/cosmos_client/src/cart/utils/get-order-settings';
import {COMMUNICATION_TYPE_TO_HELPER} from '@apress/gems/cosmos_client/src/common/communication/constants/communication-type-to-helper';
import {getCommunicationType} from '@apress/gems/cosmos_client/src/common/communication/helpers/get-communication-type';
import contactPhone from './contact-phone/contact-phone';
import get from 'lodash.get';

app.modules.ordersUserDataFields = (function (self) {
  const authModules = get(app, 'modules.authComponent');

  function _getFieldsOptions(order) {
    const communicationType = getCommunicationType(getOrderSettings(order));
    return COMMUNICATION_TYPE_TO_HELPER[communicationType](order);
  }

  function _renderUserDataFields(order, authComponentAdditionalOptions) {
    if (!(app.config.orderForm && authModules)) {
      return;
    }
    const config = app.config.orderForm.config,
      isCustomAuth = config['custom_authorization'],
      fields = _getFieldsOptions(order),
      requiredFields = isCustomAuth
        ? get(fields, 'auth.required', [])
        : config['required_fields'],
      fieldsSet = isCustomAuth ? get(fields, 'auth.set', []) : config.fieldset,
      withMultipleField = fieldsSet.includes('multiple'),
      requiredPlaceholder = config['required_placeholder'],
      fieldsSettings = config['fields_settings'],
      showPhoneOnForm = config['show_phone_form'],
      isHideNameField = config['need_name_field'];

    const options = Object.assign(
      {
        simpleRegistration: true,
        crossDomainAuth: app.config.crossDomainAuth,
        orientation: app.config.isMobile
          ? 'vertical'
          : config['orientation_fields'],
        containerName: config['quick_form']
          ? 'fastOrderInPopup'
          : 'orderInPopup',
        withoutSpinner: true,
        cache: false,
        fields: {
          set: fieldsSet,
          required: requiredFields,
          withMultipleField: withMultipleField,
          withoutPlaceholder:
            !isCustomAuth && withMultipleField && !requiredPlaceholder,
          inline: !!config['inline_fields'],
          hiddenByDefault: ['password'],
          maxlength:
            config['auth_config'] && config['auth_config']['max_length'],
          needPhoneMaskOptions: true,
        },
      },
      authComponentAdditionalOptions
    );

    if (fieldsSettings) {
      options.customFormSettings = {
        fieldsSettings: fieldsSettings,
      };
    }

    if (isHideNameField) {
      fields.auth?.required.pop();
    }

    if (fieldsSet.length) {
      const renderEvent = {
        target: document.querySelector('.js-auth-component-container'),
      };
      authModules.onRenderComponent(renderEvent, options);
    }

    if (!(isCustomAuth && fields.contacts)) {
      return;
    }

    if (_isNeedPhone(fields, order, showPhoneOnForm)) {
      contactPhone.toggle(true, order.user);
      contactPhone.listener();
    }
  }

  function _isNeedPhone(fields, order, showPhoneOnForm) {
    return (
      fields.contactsRequired ||
      ((order.user?.email || order.user?.phone) && showPhoneOnForm) ||
      order.company?.order_settings.forms.require_phone
    );
  }

  function processUserData($form) {
    const $authComponent = $form.find('.js-auth-component-container');
    var $$processUser = $.Deferred();

    $authComponent.trigger('processUserData:authComponent', [$$processUser]);

    return $$processUser;
  }

  self = {
    render: _renderUserDataFields,
    processUserData,
  };
  return self;
})(app.modules.ordersUserDataFields || {});

export default app.modules.ordersUserDataFields;
