import {
  getPayments,
  paymentWithConfirmation,
} from '../../../../src/assets/js/utils/orders/online-payment';
import getPaymentProviders from '@apress/gems/cosmos_client/src/cart/utils/get-payment-providers';
import payOnline from '@apress/gems/cosmos_client/common/online-payment/pay-online';
import renderPaykeeperPopup from '../pay-keeper-popup/pay-keeper-popup';
import createOrder from '../../../../src/assets/js/shared/orders/create_order';
import '../choose-payment-type/choose-payment-type';
import sendAnalytics from '../utils/send-analytics.js';

const onlinePayment = (() => {
  const SELECTORS = {
    onlineInput:
      '.js-choose-payment-type__toggle[id=choose-payment-type-online]',
    fullMessage: '.js-order-form-full-message',
  };

  const onSuccessSendOrder = (data, onSuccess) => {
    const order = data.order;
    const payments = getPayments(order);
    const paymentProviders = getPaymentProviders(payments);
    const withConfirmation = paymentWithConfirmation(payments);

    if (withConfirmation || !order['items_total_price']['exact_value']) {
      onSuccess(data);
      return;
    }

    if (paymentProviders.includes('paykeeper')) {
      const modalDialogEl = document.querySelector('.js-modal-dialog');
      modalDialogEl && modalDialogEl.remove();
      document.dispatchEvent(new Event('closeAllPopup:popups'), {
        bubbles: false,
      });
      renderPaykeeperPopup(order, () => onSuccess(data));
    } else {
      onSuccess(data);
      payOnline(order.id, paymentProviders[0]);
    }
    sendAnalytics('send');
  };

  const sendOrderAndPay = (orderType, formData, onSuccess, onFail) => {
    const input = document.querySelector(SELECTORS.onlineInput);
    const messageInput = document.querySelector(SELECTORS.fullMessage);
    const onlineMessage = input
      ? input.dataset.message
      : app.i18n['online_payment'].message;
    const message = (messageInput && `${messageInput.value}.\n`) || '';
    formData.append('order[online_payment]', true);
    formData.set(
      'order[messages_attributes][0][text]',
      `${message}${onlineMessage}`
    );
    createOrder(
      orderType,
      formData,
      (data) => onSuccessSendOrder(data, onSuccess),
      onFail
    );
  };

  return {
    sendOrderAndPay,
  };
})();

app.modules.onlinePayment = onlinePayment;
export default onlinePayment;
