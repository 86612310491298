// Модуль выбора регионов на мобильной версии
import regionPopup from './templates/regions-select.handlebars';
import Cookies from 'js-cookie';

app.modules.chooseRegion = (function (self) {
  let _$searchField;
  let _regions;

  function _setRegionCookie(value) {
    Cookies.set('root_region', value, {
      domain: app.config.currentHost,
      expires: 366,
      path: '/',
    });
  }

  function _setCheckedCookie(value) {
    Cookies.set('region_checked', value, {
      domain: app.config.currentHost,
      path: '/',
    });
  }

  function _filterRegions() {
    const $regions = $('.js-region-list').find('.js-rl-link');
    const $searchEmpty = $('.js-search-empty');
    let filter;
    let emptySearch = true;

    $searchEmpty.hide();
    filter = app.modules.projectUtils
      .enRuSwitch({
        str: _$searchField.val(),
        direction: _$searchField.data('direction'),
      })
      .toUpperCase();

    $regions.each(function () {
      var $region = $(this);
      if ($region.text().toUpperCase().indexOf(filter) > -1) {
        $region.closest('.js-rl-item').show();
        emptySearch = false;
      } else {
        $region.closest('.js-rl-item').hide();
      }
    });
    emptySearch && $searchEmpty.show();
  }

  function _clearSearch() {
    _$searchField.val('');
    $('.js-rl-item').show();
    $('.js-search-empty').hide();
  }

  function _selectRegion(event) {
    event.preventDefault();

    const $this = $(this);
    const params = $this.data();

    _setRegionCookie(params.region);
    _setCheckedCookie('t');

    location.href = params.url;
  }

  function _createRegionPopup() {
    const contentHTML = document.createElement('div');
    contentHTML.classList.add('change-region-popup');
    contentHTML.innerHTML = regionPopup(_regions);

    app.modules.modalDialog.create({
      content: contentHTML,
      title: app.i18n.popupChoseRegion.title,
      dialogClass: 'change-region-popup-modal',
    });
  }

  function _renderRegionsPopupNew() {
    if (_regions) {
      _createRegionPopup();
      _$searchField = $('.js-search-region');
      return;
    }

    $.ajax({
      url: app.config.mainToolbar.ajaxRegionsUrl,
      success: function (response) {
        _regions = response;
        _createRegionPopup();
      },
      complete: function () {
        _$searchField = $('.js-search-region');
      },
    });
  }

  function _listener() {
    $doc
      .on('keyup search', '.js-search-region', _filterRegions)
      .on('click', '.js-search-clear', _clearSearch)
      .on('click', '.js-change-region-item', _renderRegionsPopupNew)
      .on('click', '.js-rl-link', _selectRegion)
      .on('openPopup:selectRegionMobile', _renderRegionsPopupNew);
  }

  self.load = _listener;

  return self;
})(app.modules.chooseRegion || {});
