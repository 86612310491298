// Управление количеством товара в мобильных попапах заказа.
import debounce from 'lodash.debounce';
import orderForm from './order-form';

export default app.modules.orderFormQty = ((self = {}) => {
  function orderFormQtyListener(form) {
    const countInput = form.querySelector('.js-of-count');

    countInput.addEventListener('input', debounce(orderForm.getDraft, 500));
    countInput.addEventListener('blur', function (evt) {
      !parseInt(evt.target.value) && (evt.target.value = 1);
    });
  }

  self.listener = orderFormQtyListener;
  return self;
})(app.modules.orderFormQty || {});
