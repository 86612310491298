// Обработка переключения типа оплаты (онлайн/наличные)
import {
  getPayments,
  hasOnlinePaymentProvider,
  hasLiqPay,
  isOnlinePaymentAvailable,
  paymentWithConfirmation,
} from '../../../../src/assets/js/utils/orders/online-payment';
import choosePaymentTypeTemplate from './templates/choose-payment-type.handlebars';

const choosePaymentType = (() => {
  const SELECTORS = {
      block: '.js-choose-payment-type',
      input: '.js-choose-payment-type__toggle[name=choose-payment-toggle]',
      onlineInput: '#choose-payment-type-online',
      cashInput: '#choose-payment-type-cash',
      checkedInput:
        '.js-choose-payment-type__toggle[name=choose-payment-toggle]:checked',
      submitBtn: '#order-form-submit',
    },
    locales = window.app.i18n['online_payment'];
  let _canPayOnline, _block;

  const setSubmitBtnText = (isOnline) => {
    if (!_block) {
      return;
    }
    const btn = _block
      .closest('.js-order-form')
      .querySelector(SELECTORS.submitBtn);
    btn.value = isOnline ? locales['submit_value'] : btn.dataset.submitValue;
  };

  const onPaymentTypeChange = (evt) => {
    const type = evt.target.value;
    setSubmitBtnText(type === 'online' && _canPayOnline);
  };

  const _prepareChooseTemplateData = (order) => {
    if (!order) {
      return;
    }
    const payments = getPayments(order);
    const withConfirmation = paymentWithConfirmation(payments);
    const blockText = withConfirmation
      ? locales['payment_with_confirmation']
      : locales['block_note'];

    return {
      title: locales['block_title'],
      blockText,
      showMir: !hasLiqPay(payments),
      chooseOnline: locales['choose_online'],
      chooseCash: locales['choose_cash'],
      onlineValue: withConfirmation ? 'confirmation' : 'online',
      message: locales.message,
    };
  };

  const init = () => {
    const inputs = document.querySelectorAll(SELECTORS.input);
    [].forEach.call(inputs, (input) =>
      input.addEventListener('change', onPaymentTypeChange)
    );
  };

  const toggleBlockVisibility = (order) => {
    _canPayOnline = isOnlinePaymentAvailable(order);
    const input = document.querySelector(
      _canPayOnline ? SELECTORS.onlineInput : SELECTORS.cashInput
    );
    if (!input) {
      return;
    }
    input.checked = true;
    _block.classList.toggle('dn', !_canPayOnline);
  };

  const updatePaymentText = (order) => {
    if (!(_block && order)) {
      return;
    }
    const checkedInput = document.querySelector(SELECTORS.checkedInput);
    if (!checkedInput) {
      return;
    }

    toggleBlockVisibility(order);
    setSubmitBtnText(checkedInput.value === 'online' && _canPayOnline);
  };

  const render = (order) => {
    _block = document.querySelector(SELECTORS.block);
    if (!(_block && order && hasOnlinePaymentProvider(order))) {
      return;
    }

    _block.innerHTML = choosePaymentTypeTemplate(
      _prepareChooseTemplateData(order)
    );
    toggleBlockVisibility(order);
    updatePaymentText(order);
    init();
  };

  return {
    render,
    update: updatePaymentText,
  };
})();

app.modules.choosePaymentType = choosePaymentType;
export default choosePaymentType;
