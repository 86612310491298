var Handlebars = require("../../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-pay-keeper-popup\">\n  <div class=\"pay-keeper-popup__info\">\n    <div class=\"pay-keeper-popup__info-item\">\n      <div class=\"pay-keeper-popup__info-header\">"
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.orderId",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":49},"end":{"line":4,"column":82}}}))
    + "</div>\n      <div class=\"pay-keeper-popup__order-id\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"order_id") : stack1), depth0))
    + "</div>\n    </div>\n    <div class=\"pay-keeper-popup__info-item\">\n      <div class=\"pay-keeper-popup__info-header\">"
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.price",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":49},"end":{"line":8,"column":80}}}))
    + "</div>\n      <div class=\"pay-keeper-popup__price\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"formatted_total_price") : stack1), depth0))
    + "</div>\n    </div>\n  </div>\n  <form\n    action=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"paykeeper_url") : stack1), depth0))
    + "/create\"\n    class=\"pay-keeper-popup__form js-pay-keeper-popup__form\"\n    method=\"POST\"\n    target=\"_blank\"\n  >\n    <input type=\"text\" name=\"sum\" value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"total_price") : stack1), depth0))
    + "\" hidden>\n    <input type=\"text\" name=\"orderid\" value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"order_id") : stack1), depth0))
    + "\" hidden>\n    <input type=\"text\" name=\"service_name\" value=\""
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.serviceName",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":50},"end":{"line":20,"column":87}}}))
    + " "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"company_name") : stack1), depth0))
    + "\" hidden>\n    <input type=\"text\" name=\"cart\" value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"cart") : stack1), depth0))
    + "\" hidden>\n\n    <label class=\"pay-keeper-popup__form-label\">\n      "
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.userName",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":6},"end":{"line":24,"column":40}}}))
    + "\n      <input\n        type=\"text\"\n        class=\"pay-keeper-popup__form-input\"\n        name=\"clientid\"\n        value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"customer_name") : stack1), depth0))
    + "\"\n        maxlength=\"200\"\n      >\n    </label>\n\n    <label class=\"pay-keeper-popup__form-label\">\n      "
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.userEmail",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":6},"end":{"line":35,"column":41}}}))
    + "\n      <input\n        type=\"text\"\n        class=\"pay-keeper-popup__form-input\"\n        name=\"client_email\"\n        value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"customer_email") : stack1), depth0))
    + "\"\n        maxlength=\"200\"\n        pattern=\"^.+@.+\\..+$\"\n      >\n    </label>\n\n    <label class=\"pay-keeper-popup__form-label\">\n      "
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.userPhone",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":47,"column":6},"end":{"line":47,"column":41}}}))
    + "*\n      <input\n        type=\"text\"\n        class=\"pay-keeper-popup__form-input js-pay-keeper-popup__phone-input\"\n        name=\"client_phone\"\n        value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"customer_phone") : stack1), depth0))
    + "\"\n        maxlength=\"200\"\n        pattern=\"^((8|\\+7)[\\-\\s]?)?(\\(?\\d{3}\\)?[\\-\\s]?)?[\\d\\-\\s]{7,10}$\"\n        required\n      >\n    </label>\n\n    <button\n      class=\"pay-keeper-popup__form-submit js-pay-keeper-popup__form-submit apress-button ab-default additional- js-ga-link\"\n      type=\"button\"\n    >"
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"payKeeperPopup.submitText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":62,"column":5},"end":{"line":62,"column":41}}}))
    + "</button>\n  </form>\n</div>\n";
},"useData":true});