import orderOnlinePayment from './online-payment/online-payment';
import contactPhone from './contact-phone/contact-phone';
import choosePaymentType from './choose-payment-type/choose-payment-type';
import orderFormQty from './order-form-qty';
import ordersUserDataFields from './orders-user-data-fields';
import get from 'lodash.get';
import {sendConversionEventLog} from 'src/assets/js/utils/send-conversion-event-log/send-conversion-event-log.js';
import successPopupMobile from './order-product-success-popup/templates/success-popup.handlebars';
import sendAnalytics from './utils/send-analytics.js';

app.modules.orderForm = (function (self) {
  var _additionalData, _$form, _wasUserSigned, _$loader, _popup;
  const CONTRACT_CURRENCY_ID = 11;
  const ORDER_FORM_WITH_DEFAULT_POPUP = {
    mobile: [
      'form1',
      'form2',
      'form3',
      'form4',
      'coupon',
      'mobile_offer_message_form',
      'mobile',
      'mobile_offer',
      'discount_request',
    ],
    destop: ['discount_request'],
  };

  function _prepareDataToOrderDraft(promocode) {
    var baseFields = {details_view: true},
      subjectId = _$form.find('.js-order-subject-id').val(),
      itemsFields = {
        items_attributes: [
          {
            subject_id: subjectId,
            quantity: _prepareQuantity(_$form.find('.js-of-count')),
          },
        ],
      },
      orderFields = {
        order_type: _$form.find('.js-order-type').last().val(),
        company_id: _$form.find('.js-order-company-id').val(),
        promocode: promocode || '',
      };
    if (subjectId) {
      Object.assign(orderFields, itemsFields);
    }
    return Object.assign({}, baseFields, {order: orderFields});
  }

  function _prepareQuantity($countInputFromForm) {
    const countFormValue = parseInt($countInputFromForm.val());
    const minQuantity = parseInt($countInputFromForm.attr('min'));

    return minQuantity > countFormValue ? minQuantity : countFormValue || 1;
  }

  function _isMinTotalValidationForm(formName) {
    return !!app.config.minTotalValidationForms.filter(function (item) {
      return item === formName;
    }).length;
  }

  function _toggleOnlinePaymentAbility(order) {
    _$form
      .find('.js-of-online-payment-button')
      .prop('disabled', !order['items_total_price']['exact_value']);
  }

  function _getDraft(options) {
    options = options || {};
    var onSuccess = options.onSuccess,
      onFail = options.onFail,
      promocode = options.promocode;

    app.modules.customerOrdersAPI
      .getOrderDraft(_prepareDataToOrderDraft(promocode))
      .done(function (response) {
        var order = response.order;
        _countPrice(order);
        onSuccess && onSuccess(order);
        !promocode && $doc.trigger('updated:orderForm');
      })
      .fail(function (response) {
        onFail && onFail(response);
      });
  }

  function _getAuthComponentAdditionalOptions() {
    const isFastOrder = app.config.orderForm.config['quick_form'];
    return {
      onSearch: isFastOrder
        ? () => !app.config.isMobile && u_reRenderPopup()
        : _toggleContactPhone,
      onError: () => _showFlashError(),
      getAdditionalUserData: _getUserAdditionalData,
    };
  }

  function _renderOrderForm(order) {
    ordersUserDataFields.render(order, _getAuthComponentAdditionalOptions());
  }

  function _onOrderFormInit() {
    _getDraft({
      onSuccess: function (order) {
        const userDurable =
          get(app, 'config.currentUser.phone') ||
          get(app, 'config.currentUser.email');
        if (
          get(app, 'config.orderForm.config.custom_authorization') ||
          !userDurable
        ) {
          _renderOrderForm(order);
        }
        _$form.find('.js-send-order').prop('disabled', false);
        _updateMinTotalMessage(order);
        app.config.orderForm.config.quantity &&
          orderFormQty.listener(_$form[0]);

        if (!app.config.isMobile) {
          return;
        }
        choosePaymentType.render(order);
      },
    });
  }

  function _updateMinTotalMessage(order) {
    if (!_isMinTotalValidationForm(app.config.orderFormType)) {
      return;
    }

    var $messageBlock = _$form.find('.js-order-total-warning'),
      $formButton = _$form.find('.js-send-order');

    var data = order['price_validation'];
    if (!data) {
      return;
    }

    if (data.passed) {
      $formButton.prop({disabled: false});
      $messageBlock.addClass('hidden');
      _toggleOnlinePaymentAbility(order);
    } else {
      $formButton.prop({disabled: !!data.required});
      $messageBlock
        .html(
          app.config.orderMinTotalMessages.blockingMessage.replace(
            '_total_',
            data['min_price']
          )
        )
        .removeClass('hidden');
    }
  }

  function _getUserAdditionalData() {
    const additionalData = {
        profile_attributes: {city_id: app.config.currentRegionCapitalId},
      },
      contactPhone = document.querySelector(
        '.js-order-form .js-contact-phone__field'
      ),
      registrationPhone = document.querySelector(
        '.js-order-form .js-input-phone-auth'
      ),
      phone =
        (contactPhone && contactPhone.value) ||
        (registrationPhone && registrationPhone.value);

    phone && (additionalData['profile_attributes'].contacts = phone);
    return additionalData;
  }

  function _setDefaultComment($field) {
    var comment = $field.length && $field.val();
    if (!comment || !comment.length) {
      comment =
        app.config.orderFormType === 'discount_request'
          ? HandlebarsTemplates[
              'customer_orders/discount_request_dummy_comment'
            ]()
          : HandlebarsTemplates['customer_orders/dummy_comment']() +
            $field.data('order-subject-title');
    }
    return comment;
  }

  function _setDefaultCommentWithPhone(comment) {
    var phoneNumber =
        $('.js-of-customer_phone').val() || $('.js-input-phone-auth').val(),
      dummyComment = HandlebarsTemplates[
        'customer_orders/callback_dummy_comment'
      ]({
        phone: phoneNumber ? phoneNumber.replace(/[()]/g, '') : '',
      });

    return (comment + '\n' + dummyComment).replace(/^\n/, '');
  }

  function _prepareFullMessage() {
    var $field = $('.js-order-form-message'),
      comment = $field.val() || '';

    switch (app.config.orderFormType) {
      case 'form2':
        _getForm2Data();
        break;
      case 'form3':
        _getForm3Data();
        break;
      case 'form4':
        _getForm4Data();
        break;
      case 'call_back_form':
        return _setDefaultCommentWithPhone(comment);
      default:
        return _setDefaultComment($field);
    }

    _additionalData.comment = comment;

    //если какая-то доп.информация с формы введена, запишем ее в шаблон
    if (!$.isEmptyObject(_additionalData)) {
      comment =
        HandlebarsTemplates['customer_orders/' + app.config.orderFormType](
          _additionalData
        );
    }

    return comment;
  }

  function _getForm2Data() {
    _additionalData = {
      date: $('[data-field=date]').val(),
      nightsFrom: $('[data-field=nights_from]').val(),
      nightsTo: $('[data-field=nights_to]').val(),
      adults: $('[data-field=adults]').val(),
      children: $('[data-field=children]').val(),
    };
    _additionalData.nights =
      _additionalData.nightsFrom || _additionalData.nightsTo;
    _additionalData.comma1 =
      _additionalData.date.length > 0 && _additionalData.nights.length > 0;
    _additionalData.comma2 =
      (_additionalData.nights.length > 0 ||
        (_additionalData.nights.length < 1 &&
          _additionalData.date.length > 0 &&
          !_additionalData.comma1)) &&
      _additionalData.adults;
    _additionalData.comma3 =
      (_additionalData.adults ||
        (!_additionalData.adults &&
          (_additionalData.date.length > 0 ||
            _additionalData.nights.length > 0) &&
          !_additionalData.comma2)) &&
      _additionalData.children;
    _additionalData.hasAdditional =
      _additionalData.date ||
      _additionalData.nights ||
      _additionalData.adults ||
      _additionalData.children;
  }

  function _getForm3Data() {
    _additionalData = {
      date: $('[data-field=date]').val(),
      time: $('[data-field=time]').val(),
      guests: $('[data-field=guests]').val(),
    };
  }

  function _getForm4Data() {
    _additionalData = {
      date: $('[data-field=date]').val(),
      time: $('[data-field=time]').val(),
    };
  }

  function _setFullMessage() {
    var fullMessage = _prepareFullMessage(),
      $fullMessageField = $('.js-order-form-full-message');

    $fullMessageField.length && $fullMessageField.val(fullMessage);
  }

  function _countPrice(order) {
    _updateMinTotalMessage(order);
    _reRenderItemPrice(order);
    _reRenderOrderTotal(order);

    if (!app.config.isMobile) {
      return;
    }
    choosePaymentType.update(order);
  }

  // Пример с валютой _formatPrice(8700000, 'руб.') => 8 700 000 руб.
  // Пример без валюты _formatPrice(8700000) => 8 700 000
  function _formatPrice(price, currencyTitle) {
    var formatted = String(price)
      .split('')
      .reverse()
      .map(function (el, index) {
        var separator = index > 0 && index % 3 === 0 ? ' ' : '';
        return el + separator;
      })
      .reduceRight(function (prevVal, currVal) {
        return prevVal + currVal;
      });
    return currencyTitle ? formatted + ' ' + currencyTitle : formatted;
  }

  function _getTotalWithoutDiscount(item) {
    var total = item.subject.price * item.quantity,
      currencyTitle = item.subject.currency.title;
    return _formatPrice(total, currencyTitle);
  }

  function _reRenderOrderTotal(order) {
    var $orderTotal = $('.js-order-total-price'),
      $orderTotalInput = $('.js-order-product-total-price-field'),
      $oldTotalPriceRaw = $orderTotal.find('[data-price-type=discount-old]'),
      $totalPriceRaw = $oldTotalPriceRaw.length
        ? $orderTotal.find('.bp-price-cover')
        : $orderTotal,
      totalSum = String(order['formatted_total_with_discount_and_delivery']),
      item = order.items[0];

    _removeUnitsFromDOM($oldTotalPriceRaw);
    $totalPriceRaw.first().html(totalSum);
    $orderTotalInput.val(totalSum.replace(/\D/g, ''));
    _setTotalWithoutDiscount($oldTotalPriceRaw, item);
  }

  // Удаляем единицы измерения, выводимые в отдельном текстовом узле.
  // Актуально для товаров со скидкой, так как разметка там устроена не удобно и нет возможности её поменять.
  function _removeUnitsFromDOM($oldTotalPriceRaw) {
    if (!$oldTotalPriceRaw.length || !$oldTotalPriceRaw.parent().length) {
      return;
    }

    var unitsNode = $oldTotalPriceRaw.parent()[0].childNodes[1];
    unitsNode && unitsNode.remove();
  }

  function _setTotalWithoutDiscount($totalPriceRaw, item) {
    if (!$totalPriceRaw.length) {
      return;
    }

    var totalWithDiscount = _getTotalWithoutDiscount(item);
    _toggleOldPriceVisibility(item, $totalPriceRaw);
    $totalPriceRaw.html(totalWithDiscount);
  }

  function _reRenderItemPrice(order) {
    var $currentPrice = $('.js-order-current-price'),
      $discountOld = $currentPrice.find('[data-price-type=discount-old]'),
      $priceRaw = $discountOld.length
        ? $currentPrice.find('.bp-price-cover')
        : $currentPrice,
      item = order.items[0];
    const countOrderItem = document.querySelector('.js-of-count');

    if (!item) {
      return;
    }

    const isRetailOrContractCurrency = _isRetailOrContractCurrency(item);

    var itemPrice =
      item['wholesale?'] || !isRetailOrContractCurrency
        ? _prepareTitleForWholesale(item, isRetailOrContractCurrency)
        : item['formatted_total'];

    $priceRaw.first().html(itemPrice);

    if (!isRetailOrContractCurrency && countOrderItem) {
      countOrderItem.value =
        countOrderItem.value > item['wholesale_min_qty']
          ? countOrderItem.value
          : item['wholesale_min_qty'];
      countOrderItem.min = item['wholesale_min_qty'];
    }

    app.config.isMobile
      ? _setTotalWithoutDiscount($discountOld, item)
      : _setItemPriceWithoutDiscount($discountOld, item);
  }

  function _isRetailOrContractCurrency(ordersItemData) {
    return (
      ordersItemData['retail_price'] ||
      (!ordersItemData['retail_price'] &&
        ordersItemData['subject']['currency']['id'] == CONTRACT_CURRENCY_ID) ||
      !ordersItemData['subject']['wholesale_min_qty']
    );
  }

  function _prepareTitleForWholesale(
    ordersItemData,
    isRetailOrContractCurrency
  ) {
    return !isRetailOrContractCurrency
      ? `
      ${app.i18n.ordersCartAddCartPopup.titleWholesale} ${ordersItemData['formatted_wholesale_price']} ${app.i18n['titleWholesaleMinQty']}
    `
      : ordersItemData['formatted_total'];
  }

  function _setItemPriceWithoutDiscount($discountOld, item) {
    if (!$discountOld.length) {
      return;
    }

    var itemPrice = String(item.subject.price).replace(/.0$/, '');
    _toggleOldPriceVisibility(item, $discountOld.parent());
    $discountOld.html(itemPrice);
  }

  function _toggleOldPriceVisibility(item, $discountContainer) {
    var withDiscount = itemWithDiscount(item);
    $discountContainer.toggleClass('dn', !withDiscount);
  }

  function itemWithDiscount(item) {
    var subject = item.subject;
    if (!subject || item['wholesale?']) {
      return;
    }

    var expires = Date.parse(String(subject['discount_expires_at'])),
      today = Date.now();
    return !!(subject['is_discount'] && today - expires < 0);
  }

  function _toggleContactPhone(data) {
    var $phone = $('.js-of-new-user-phone');

    if (data.user && data.user.id) {
      $('.js-of-contact-phone').val('');
      $phone.addClass('dn');
      $doc.trigger('showSignInFields:authComponent');
    } else {
      $phone.removeClass('dn');
      $doc.trigger('showSignUpFields:authComponent');
    }
  }

  function _setOrderPhone() {
    var $this = $(this);

    $('.js-of-field-for-phone').val(function () {
      try {
        return $this.cleanVal();
      } catch (e) {
        return $this.val();
      }
    });
  }

  function _verifyAndInitializeUser() {
    return new Promise((resolve) => {
      const {email, phone, sid} = app.config.currentUser;

      _wasUserSigned = email || phone || false;
      if (_wasUserSigned || sid) {
        resolve();
      } else {
        app.modules.customerOrdersUtils.registerWithSID().then(resolve);
      }
    });
  }

  function _orderFormInit() {
    _$form = $('.js-order-form');

    $('.js-order-product-total-price-field').val(
      $('.js-order-current-price .bp-price:eq(0)').text()
    );
    $('.js-numeric').numeric({negative: false});
    $('.js-toggle').toggler();
    !app.config.isMobile && $('.js-check-length').checkLength();
    app.modules.selectCheck && app.modules.selectCheck.load();

    _verifyAndInitializeUser().then(_onOrderFormInit);

    sendAnalytics('open');
  }

  function _showErrors($form, params) {
    var $field;
    if (!params.responseJSON) {
      _showFlashError($form);
      return;
    }
    $.each(params.responseJSON.errors, function (index, item) {
      $.each(item, function (key, value) {
        $field = $form.find('.js-of-' + key);
        $field
          .data({other: value})
          .addClass('js-of-field-with-error error-on other')
          .closest('.js-of-required')
          .addClass('error-on other');
        if (app.config.isMobile) {
          $field.addClass('error');
        }
      });
    });
  }

  function _getFormData($form) {
    var formData = new FormData(),
      formFields = ['input', 'textarea', 'select'],
      promocodeField = $form.find('.js-popup-promo-code__input:disabled');

    $form
      .find(
        formFields
          .map(function (formField) {
            return formField + ':not(:disabled)';
          })
          .join(',')
      )
      .each(function () {
        var $this = $(this),
          type = $this.attr('type'),
          fieldName = $this.attr('name'),
          fieldValue = $this.is('.js-attach-file-button')
            ? this.files[0]
            : $this.val() || $this.data('defaultValue');

        if (type && type.match(/checkbox|radio/) && !$this.prop('checked')) {
          return;
        }

        fieldValue && formData.append(fieldName, fieldValue);
      });

    formData.append('order[source_url]', location.href);
    promocodeField.length &&
      formData.append('order[promocode]', promocodeField.val());

    return formData;
  }

  function _sendOrder($form, isOnline) {
    var orderType = $form.find('.js-order-type').last().val(),
      formData = _getFormData($form),
      paymentMethod = formData.get('choose-payment-toggle') || 'cash',
      failHandler = _onFailSendOrder($form),
      url =
        app.config.api[
          orderType === 'tender' ? 'sellerOrdersURL' : 'customerOrdersURL'
        ];

    if (paymentMethod === 'online' || isOnline) {
      orderOnlinePayment.sendOrderAndPay(
        orderType,
        formData,
        _onSuccessSendOrder,
        failHandler
      );
    } else {
      $.ajax({
        url: url,
        method: 'POST',
        processData: false,
        contentType: false,
        data: formData,
      })
        .done(function (data, textStatus, jqXHR) {
          if (_isDefaultPopup(app.config.orderFormType)) {
            data.order.isVueForm = $form.data('isVueForm');
          }
          _onSuccessSendOrder(Object.assign({}, data, jqXHR));
          sendAnalytics('send');
        })
        .fail(failHandler);
    }
  }

  function _onFailSendOrder($form) {
    return function (data) {
      _showErrors($form, data);

      if (
        !_wasUserSigned &&
        (app.config.currentUser.email || app.config.currentUser.phone)
      ) {
        $form.find('.js-of-send-options').remove();
      }
      _unlockButtons($form);
    };
  }

  function _getDataForSuccessPopup(order) {
    if (!order) {
      return;
    }
    var urlType =
      order['order_type'] === 'tender' ? 'sellerOrderURL' : 'customerOrderURL';

    return $.extend(
      {},
      {
        order: order,
        order_url: app.config.orderFormPopup[urlType].replace(':id', order.id),
        template: app.config.orderFormType,
        sendType: app.config.orderFormSendType,
        callbackOpen: _popup && _closeOrderPopup,
      }
    );
  }

  function _sendItemsConversionEvent(order) {
    if (order.items && order.items.length) {
      sendConversionEventLog(
        {
          type: 'order',
          productId: order.items[0]['subject_id'],
          userId: order.user.id,
        },
        _getConversionItemAttribute(order)
      );
    }
  }

  function _getConversionItemAttribute(order) {
    const orderSubjectId = order.items[0].subject_id;

    return (
      $(`[data-conversion-order-item=${orderSubjectId}]`) ||
      $(`.js-catalogue-item[data-product-id=${orderSubjectId}]`)
    );
  }

  function _renderMobileSuccessPopup(order) {
    var dataForSuccessPopup = _getDataForSuccessPopup(order);

    if (!order.isVueForm) {
      $doc.trigger('insert:popups:mobile', {
        tmpl: 'orders/success_popup_mobile',
        params: dataForSuccessPopup,
      });
      _popup && _closeOrderPopup();
    } else {
      const orderSuccessPopupWrapper = document.createElement('div');
      const dataOrderSuccessPopup = app.config.orderSuccessPopup;

      orderSuccessPopupWrapper.innerHTML = successPopupMobile(
        Object.assign({}, dataOrderSuccessPopup, dataForSuccessPopup)
      );

      _popup.update({
        content: orderSuccessPopupWrapper,
        close: _checkDurableUserAndReloadPage,
      });
    }
  }

  function _renderSuccessPopup(order) {
    var dataForSuccessPopup = _getDataForSuccessPopup(order);
    var $orderDialog = $('<div></div>');

    $orderDialog.html(
      HandlebarsTemplates['orders/success_popup'](dataForSuccessPopup)
    );

    _popup.update({
      content: $orderDialog[0],
      dialogClass: 'send-message-popup order-success js-order-form-popup',
      close: function () {
        _wasUserSigned || location.reload();
      },
    });
  }

  function _onSuccessSendOrder(data) {
    var $orderDialog = $('.js-order-popup-form-wrapper');
    var order = data.order;

    if (data.status !== 201) {
      $orderDialog.html(data);
      return;
    }
    _sendItemsConversionEvent(order);

    app.config.isMobile
      ? _renderMobileSuccessPopup(order)
      : _renderSuccessPopup(order);

    if (!order) {
      return;
    }
    $doc.trigger('checkout:ordersItem', order);
  }

  function _unlockButtons($form) {
    $form.find('.js-send-order').prop({disabled: false});
  }

  function _validateFields($form) {
    var $requiredFields = $form.find('.js-of-required:visible'),
      errorsCount = 0;

    if (!$requiredFields.length) {
      return true;
    }

    $requiredFields.removeClass('error-on');
    $('.js-of-required-field').removeClass('error');

    $requiredFields.each(function () {
      var $this = $(this),
        $field = $this.find('.js-of-required-field'),
        $fieldValue = $field.val();
      if (
        ($field.attr('type') === 'tel' && !parseInt($fieldValue)) ||
        !$fieldValue.length
      ) {
        $field
          .addClass('error')
          .closest('.js-of-required')
          .addClass('error-on');
        errorsCount++;
      }
      if (
        $field.attr('type') === 'tel' &&
        parseInt($fieldValue) &&
        $fieldValue.length < 18
      ) {
        $field.closest('.js-of-required').addClass('error-on other');
      }
    });
    document.dispatchEvent(
      new Event(!errorsCount ? 'authEvent:valid' : 'authEvent:errorValid')
    );

    return !errorsCount;
  }

  function _removeErrors($form) {
    $form
      .find('.js-of-flash-message')
      .remove()
      .end()
      .find('.js-of-field-with-error')
      .removeClass('js-of-field-with-error error error-on other')
      .end()
      .find('.js-of-required:visible')
      .removeClass('error-on');
  }

  function _showFlashError($form) {
    var $flashContainer = $form || $('.js-of-flash-container');

    $form = $form || $('.js-order-form');
    $flashContainer.prepend(HandlebarsTemplates['orders/flash_message']());
    _unlockButtons($form);
  }

  const canSendOrder = ($form) =>
    Promise.all([
      new Promise(function (resolve, reject) {
        if (!_validateFields($form)) {
          return reject();
        }
        resolve();
      }),
      new Promise(function (resolve, reject) {
        if (
          !$form.find('.js-auth-component').length ||
          app.config.currentUser.email
        ) {
          return resolve();
        }
        ordersUserDataFields.processUserData($form).done(resolve).fail(reject);
      }),
    ]);

  function _submitOrder($form, isOnline) {
    $form = $form || $('.js-order-form');

    _removeErrors($form);

    return canSendOrder($form)
      .then(function () {
        _sendOrder($form, isOnline);
        contactPhone.saveToProfile($form[0]);
      })
      .catch(function () {
        _unlockButtons($form);
      });
  }

  function _getDialogClass(isVuePopup) {
    const addClass = ['order-form-dialog', 'order-popup-wrapper'];

    if (isVuePopup) {
      addClass.push('order-form-vue-popup');
      app.config.isMobile && addClass.push('order-form-vue-popup_mobile');
    }

    return addClass.join(' ');
  }

  function _checkDurableUserAndReloadPage() {
    const {currentUser, isUserDurableOnLoad} = app.config;

    if (!isUserDurableOnLoad && (currentUser.email || currentUser.phone)) {
      location.reload();
      return;
    }
  }

  function _isDefaultPopup(template) {
    return ORDER_FORM_WITH_DEFAULT_POPUP[
      app.config.isMobile ? 'mobile' : 'destop'
    ].includes(template);
  }

  function _showOrderForm($this, response, data) {
    let $dialog;
    const isDefaultPopup = _isDefaultPopup($this.data('params').template);
    const sendConversionEventClose = function () {
      sendConversionEventLog(
        {
          type: 'form',
          productId: data.params['product_id'],
          userId: app.config.currentUser.id,
        },
        $this
      );
    };

    if (app.config.isMobile && !isDefaultPopup) {
      $doc.trigger('insert:popups:mobile', {
        sourceElement: $this,
        tmpl: 'orders/mobile_orders_popup',
        clickCloseCallback: sendConversionEventClose,
        params: {content: response},
      });
      $dialog = $('.js-order-form');
    } else {
      $('.js-order-popup-form-wrapper').remove();

      const dialogClass = _getDialogClass(isDefaultPopup);
      $dialog = $(HandlebarsTemplates['orders/order_form']()).append(
        $(response)
      );

      _popup = app.modules.modalDialog.create({
        content: $dialog[0],
        dialogClass,
        open: function () {
          document.dispatchEvent(new Event('render:CustomForm'));
          app.modules.customerOrdersUtils.lockScroll();
        },
        close: function () {
          app.modules.customerOrdersUtils.unlockScroll();
          sendConversionEventClose();
          document.dispatchEvent(new Event('authEvent:valid'));
        },
      });
    }

    var $inputPhone = $dialog.find('.js-input-phone-auth'),
      $customerPhone = $dialog.find('.js-of-customer_phone');

    if (app.config.userContactPhone) {
      $inputPhone.val(app.config.userContactPhone);
      $customerPhone.val(app.config.userContactPhone);
    }

    $inputPhone.blur();
    $customerPhone.blur();

    $doc.trigger('createdOrderFromPopupCallback:getOrderFormPopup');
    $doc.trigger('init:promoCode', [$dialog, data.params['company_id']]);
    app.modules.attachmentsFields && app.modules.attachmentsFields.load();
  }

  function _getOrderForm($this, data) {
    if ($this.hasClass('disabled')) {
      return;
    }
    $this.addClass('disabled');

    if (!app.config.isMobile) {
      app.modules.customerOrdersUtils.openPopup.bind(null, _$loader);
    }

    if (app.config.isCompanyPage) {
      data.params = Object.assign({}, data.params, {place: 'company'});
    }

    $.ajax({
      url: app.config.getOrderPopupURLs[data.type],
      data: data.params,
    })
      .always(function () {
        app.config.isMobile ||
          app.modules.customerOrdersUtils.closePopup.bind(null, _$loader);
        $this.removeClass('disabled');
      })
      .done(function (response) {
        _showOrderForm($this, response, data);
      })
      .fail(function (xhr, textStatus, error) {
        if (xhr.status === 503) {
          _showLockPopup($this);
        } else {
          console.error(error);
        }
      });
  }

  function _showLockPopup($this) {
    var params = {
      cmsUrl: app.config.pageUrls.order + '?from=' + location.href,
      mobile: app.config.isMobile,
    };

    if (app.config.isMobile) {
      $doc.trigger('insert:popups:mobile', {
        sourceElement: $this,
        tmpl: 'shared/orders_locked',
        params: params,
      });
    } else {
      var $dialog = $('.js-dialog-popup-box').html(
        HandlebarsTemplates['shared/orders_locked'](params)
      );

      _popup = app.modules.modalDialog.create({
        content: $dialog[0],
        dialogClass: 'dialog-popup orders-locked-popup',
      });
    }
  }

  function _reloadIfUserWasNotSigned() {
    _wasUserSigned || location.reload(true);
  }

  function _onOrderSubmit(event, isOnline) {
    var $form = event.type === 'submit' ? $(this) : $('.js-order-form');

    _setFullMessage();

    if (app.config.orderFormType === 'coupon') {
      app.modules.couponForm.prepare($form);
    } else {
      _submitOrder($form, isOnline);
    }
    event.preventDefault();
  }

  function _onGetOrderBtnCLick(event) {
    event.preventDefault();

    var $this = $(this),
      data = $this.data();

    if (
      app.config.globalLocked ||
      app.config.ordersUpdatesLocked ||
      app.modules.customerOrdersUtils.isLockedPopup(data.type)
    ) {
      _showLockPopup($this);
    } else {
      _getOrderForm($this, data);
    }
    event.preventDefault();
  }

  function _closeOrderPopup() {
    _popup.close();
  }

  function _listener() {
    $doc
      .on('orderForm.init', _orderFormInit)
      .on('focus', '.js-of-customer_phone', function () {
        $(this).trigger('init:phoneMask');
      })
      .on('input', '.js-of-customer_phone', _setOrderPhone)
      .on('submit', '.js-order-form', _onOrderSubmit)
      .on('click', '.js-of-online-payment-button', function (evt) {
        _onOrderSubmit(evt, true);
      })
      .on('click', '.js-close-order-popup', _closeOrderPopup)
      .on(
        'submit',
        '.js-deals-subscription-form-in-order-popup',
        _closeOrderPopup
      )
      .on('click', '.js-get-order-form', _onGetOrderBtnCLick)
      .on('generateCodeComplete:authComponent', function () {
        !app.config.isMobile && u_reRenderPopup();
      })
      .on('closePopup.popups', function (event, data) {
        if (data && $(data[0]).hasClass('js-order-form-call')) {
          _reloadIfUserWasNotSigned();
        }
      })
      .on('blur', 'js-of-count', _getDraft);
  }

  function _init() {
    _listener();

    if (window.location.hash.includes('open_message_popup')) {
      setTimeout(function () {
        $('.js-message-button').trigger('click');
      }, 0);
      window.history.replaceState(null, null, window.location.pathname);
    }

    if (window.location.hash.includes('open_callback_popup')) {
      setTimeout(function () {
        $('.js-callback-button').trigger('click');
      }, 0);
      window.history.replaceState(null, null, window.location.pathname);
    }
  }

  self = {
    load: _init,
    submit: _submitOrder,
    getDraft: _getDraft,
    verifyAndInitializeUser: _verifyAndInitializeUser,
    renderOrderForm: _renderOrderForm,
  };

  return self;
})(app.modules.orderForm || {});

export default app.modules.orderForm;
