var Handlebars = require("../../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"popup-promo-code\">\n  <label\n    class=\"popup-promo-code__label\"\n    for=\"popup-promo-code__input\"\n  >"
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"promo_codes_label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":3},"end":{"line":5,"column":31}}}))
    + "</label>\n  <div class=\"popup-promo-code__input-wrap\">\n    <input\n      id=\"popup-promo-code__input\"\n      class=\"popup-promo-code__input js-popup-promo-code__input\"\n      type=\"text\"\n      placeholder=\""
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"promo_codes_placeholder",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":19},"end":{"line":11,"column":53}}}))
    + "\"\n      autocomplete=\"off\"\n      maxlength=\"20\"\n    />\n    <button\n      class=\"popup-promo-code__button js-popup-promo-code__button\"\n      type=\"button\"\n      data-activated=\""
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"promo_codes_button_activated",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":22},"end":{"line":18,"column":61}}}))
    + "\"\n      data-text=\""
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"promo_codes_button_text",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":17},"end":{"line":19,"column":51}}}))
    + "\"\n    >"
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"promo_codes_button_text",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":5},"end":{"line":20,"column":39}}}))
    + "</button>\n  </div>\n  <p class=\"popup-promo-code__error js-popup-promo-code__error\"></p>\n</div>\n";
},"useData":true});