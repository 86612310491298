import updateUser from '@apress/gems/cosmos_client/src/cart/common/update-user';
import contactPhoneTemplate from './templates/contact-phone.handlebars';
import get from 'lodash.get';

app.modules.orderFormContactPhone = (function (self) {
  const SELECTORS = {
    field: '.js-contact-phone__field',
    container: '.js-order-form__contact-phone-wrap',
  };

  function clearErrors(evt) {
    const target = evt.target;
    const isEmpty = target.value.trim().length === 0;
    const requiredContainer = target.closest('.js-of-required');

    document.dispatchEvent(
      new Event(isEmpty ? 'authEvent:errorValid' : 'authEvent:valid')
    );
    target.classList.toggle('error', isEmpty);
    requiredContainer.classList.toggle('error-on', isEmpty);
    requiredContainer.classList.remove('other', 'whit-error');
  }

  function contactsFieldListener() {
    document
      .querySelector(SELECTORS.field)
      .addEventListener('input', clearErrors);
  }

  function toggleContactsField(show, user) {
    const container = document.querySelector(SELECTORS.container);
    if (!container) {
      return;
    }

    const phoneField = container && container.querySelector(SELECTORS.field);
    if ((phoneField && show) || (!phoneField && !show)) {
      return;
    }

    container.innerHTML = show
      ? contactPhoneTemplate({phone: get(user, 'profile.contacts')})
      : '';
    if (!show) {
      return;
    }
    const maskEvent = new Event('init:phoneMask', {bubbles: true});
    container.querySelector(SELECTORS.field).dispatchEvent(maskEvent);
  }

  function saveContactsToProfile(form) {
    const contactField = form.querySelector(SELECTORS.field);
    contactField && updateUser({phone: contactField.value});
  }

  self = {
    saveToProfile: saveContactsToProfile,
    toggle: toggleContactsField,
    listener: contactsFieldListener,
  };
  return self;
})(app.modules.orderFormContactPhone || {});

export default app.modules.orderFormContactPhone;
