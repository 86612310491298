import _get from 'lodash/get';
import getActivePromoCodesCount from '@apress/gems/cosmos_client/src/cart/common/get_active_promo_codes_count';
import template from './promo-code.handlebars';
import orderForm from '../../../../../blocks/shared/order-form/order-form';

app.modules.promoCode = ((self = {}) => {
  const PROMO_CODE_SELECTORS = {
    block: '.js-popup-promo-code',
    btn: '.js-popup-promo-code__button',
    input: '.js-popup-promo-code__input',
    error: '.js-popup-promo-code__error',
    sum: '.js-popup-promo-code__sum',
  };
  let btn, input, errorContainer, activated, $form;

  const validationErrors = {
    empty: 'Необходимо ввести промокод',
    cancelled: 'Промокод был отменен, т.к. был изменен состав заказа',
  };

  const setValidationError = (error) => {
    errorContainer.text(error || '');
  };

  const togglePromoCodeState = ({activate, clearField} = {}) => {
    if (!input || !btn) {
      return;
    }

    btn.text(btn.data(activate ? 'activated' : 'text'));
    btn.prop('disabled', !!activate);
    input.prop('disabled', !!activate);
    $form.find(PROMO_CODE_SELECTORS.sum).toggleClass('dn', !activate);

    if (activated && clearField) {
      input.val('');
      setValidationError(validationErrors.cancelled);
    }
    activated = activate;
  };

  const setCurrentElements = () => {
    btn = $form.find(PROMO_CODE_SELECTORS.btn);
    input = $form.find(PROMO_CODE_SELECTORS.input);
    errorContainer = $form.find(PROMO_CODE_SELECTORS.error);
    activated = false;
  };

  const deactivateCode = () => {
    togglePromoCodeState({clearField: true});
  };

  const onSuccessOrderUpdate = () => {
    setValidationError('');
    togglePromoCodeState({activate: true});
  };

  const onOrderUpdateFail = (response) => {
    setValidationError(_get(response, 'responseJSON.errors.[0].message'));
    togglePromoCodeState();
  };

  const onPromoCodeAcceptClick = () => {
    const promocode = input.val();

    if (!promocode) {
      return setValidationError(validationErrors.empty);
    }

    orderForm.getDraft({
      onSuccess: onSuccessOrderUpdate,
      onFail: onOrderUpdateFail,
      promocode,
    });
  };

  function init(event, $target, companyId) {
    $form = $target.hasClass('js-order-form')
      ? $target
      : $target.find('.js-order-form');
    const $block = $form.find(PROMO_CODE_SELECTORS.block);

    if (!$block.length) {
      return;
    }

    getActivePromoCodesCount({companyId}).then((activePromoCodesCount) => {
      if (!activePromoCodesCount) {
        return;
      }
      $block.prepend(template());
      setCurrentElements();
    });
  }

  function listener() {
    $doc
      .on('click', PROMO_CODE_SELECTORS.btn, onPromoCodeAcceptClick)
      .on('init:promoCode', init)
      .on('updated:orderForm', deactivateCode);
  }

  self.load = listener;
  return self;
})(app.modules.promoCode);
