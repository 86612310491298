function createOrder(orderType, formData, onSuccess, onFail) {
  const url =
    app.config.api[
      orderType === 'tender' ? 'sellerOrdersURL' : 'customerOrdersURL'
    ];
  const params = {
    method: 'POST',
    body: formData,
  };
  fetch(url, params)
    .then((response) => {
      return response.json().then(({order, errors}) => {
        response.ok && order
          ? onSuccess({status: response.status, order})
          : onFail({responseJSON: {errors}});
      });
    })
    .catch((error) => {
      onFail({});
      throw error;
    });
}

app.modules.createOrder = createOrder;
export default createOrder;
