var Handlebars = require("../../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <i class='choose-payment-type__provider choose-payment-type__provider_mir'></i>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='choose-payment-type'>\n  <h3 class='choose-payment-type__title'>"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":2,"column":41},"end":{"line":2,"column":50}}}) : helper)))
    + "</h3>\n  <input\n    data-message='"
    + alias4(((helper = (helper = lookupProperty(helpers,"message") || (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data,"loc":{"start":{"line":4,"column":18},"end":{"line":4,"column":29}}}) : helper)))
    + "'\n    class='aui-radio__radio aui-radio__radio_color_blue js-choose-payment-type__toggle'\n    type='radio'\n    name='choose-payment-toggle'\n    id='choose-payment-type-online'\n    value='"
    + alias4(((helper = (helper = lookupProperty(helpers,"onlineValue") || (depth0 != null ? lookupProperty(depth0,"onlineValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"onlineValue","hash":{},"data":data,"loc":{"start":{"line":9,"column":11},"end":{"line":9,"column":26}}}) : helper)))
    + "'\n  />\n  <label\n    class='choose-payment-type__label aui-radio__label'\n    for='choose-payment-type-online'\n  >\n    "
    + alias4(((helper = (helper = lookupProperty(helpers,"chooseOnline") || (depth0 != null ? lookupProperty(depth0,"chooseOnline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"chooseOnline","hash":{},"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":15,"column":20}}}) : helper)))
    + "\n  </label>\n  <p class='choose-payment-type__text'>"
    + alias4(((helper = (helper = lookupProperty(helpers,"blockText") || (depth0 != null ? lookupProperty(depth0,"blockText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"blockText","hash":{},"data":data,"loc":{"start":{"line":17,"column":39},"end":{"line":17,"column":52}}}) : helper)))
    + "</p>\n  <p class='choose-payment-type__payment-providers'>\n    <i class='choose-payment-type__provider choose-payment-type__provider_visa'></i>\n    <i class='choose-payment-type__provider choose-payment-type__provider_mastercard'></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showMir") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "  </p>\n\n  <input\n    class='aui-radio__radio aui-radio__radio_color_blue js-choose-payment-type__toggle'\n    type='radio'\n    name='choose-payment-toggle'\n    id='choose-payment-type-cash'\n    value='cash'\n  />\n  <label\n    class='choose-payment-type__label aui-radio__label'\n    for='choose-payment-type-cash'\n  >\n    "
    + alias4(((helper = (helper = lookupProperty(helpers,"chooseCash") || (depth0 != null ? lookupProperty(depth0,"chooseCash") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"chooseCash","hash":{},"data":data,"loc":{"start":{"line":37,"column":4},"end":{"line":37,"column":18}}}) : helper)))
    + "\n  </label>\n</div>\n";
},"useData":true});