import get from 'lodash.get';
import getPaymentProviders from '@apress/gems/cosmos_client/src/cart/utils/get-payment-providers';

export const getPayments = (order) =>
  get(order, 'company.order_settings.payments', {});

export const hasLiqPay = ({liqpay}) => !!liqpay;

export const hasOnlinePaymentProvider = (order) =>
  !!getPaymentProviders(getPayments(order)).length;

export const isOnlinePaymentAvailable = (order) =>
  hasOnlinePaymentProvider(order) &&
  get(order, 'items_total_price.exact_value');

export const paymentWithConfirmation = ({common} = {}) =>
  get(common, 'require_order_confirmation');
