const TYPE_ACTION = {
  send: 'sendId',
  open: 'openedId',
};

export default function sendAnalytics(type) {
  if (!app.config.orderFormAnalytics) return;

  document.dispatchEvent(
    new CustomEvent('send:externalAnalytics', {
      detail: app.config.orderFormAnalytics[TYPE_ACTION[type]],
    })
  );
}
