// Оплата через пейкипер

import mobilePopup from 'blocks/mobile/shared/pay-keeper-popup/templates/pay-keeper-popup.handlebars';
import desktopPopup from 'blocks/desktop/shared/pay-keeper-popup/templates/pay-keeper-popup.handlebars';
import {getPayKeeperCart} from '@apress/apress-frontend-utils/utils/get-pay-keeper-cart/get-pay-keeper-cart';
import _get from 'lodash.get';

const SELECTORS = {
  phoneInput: '.js-pay-keeper-popup__phone-input',
  closeBtn: '.js-pay-keeper-popup__close',
  popupEl: '.js-pay-keeper-popup__content',
  form: '.js-pay-keeper-popup__form',
  submitBtn: '.js-pay-keeper-popup__form-submit',
};
let _doAfterClose, _popupEl, _phoneInput, _form;

const closePopup = () => {
  _popupEl ? _popupEl.remove() : $('.js-pay-keeper-popup').dialog('destroy');
};

const onFormSubmit = () => {
  if (!_form.checkValidity()) {
    _form.reportValidity();
    return;
  }
  _form.submit();
  // Таймаут нужен, чтобы форма успела отправиться прежде, чем исчезнет из DOM
  setTimeout(() => {
    closePopup();
    _doAfterClose && _doAfterClose();
  }, 500);
};

const initPhoneMask = () =>
  _phoneInput.dispatchEvent(new Event('init:phoneMask', {bubbles: true}));

const init = () => {
  const closeBtn = document.querySelector(SELECTORS.closeBtn);
  const submitBtn = document.querySelector(SELECTORS.submitBtn);
  submitBtn && submitBtn.addEventListener('click', onFormSubmit);
  closeBtn && closeBtn.addEventListener('click', closePopup);
  _popupEl = document.querySelector(SELECTORS.popupEl);
  _phoneInput = document.querySelector(SELECTORS.phoneInput);
  _form = document.querySelector(SELECTORS.form);
  initPhoneMask();
};

const getDataForRender = (order) => ({
  formatted_total_price: order['formatted_total_with_discount_and_delivery'],
  total_price: parseFloat(order['items_total_price']['exact_value']),
  order_id: order.id,
  paykeeper_url: _get(order, 'company.order_settings.payments.paykeeper.url'),
  company_name: _get(order, 'company.name'),
  customer_name: _get(order, 'user.profile.name'),
  customer_email: _get(order, 'user.email'),
  customer_phone:
    order['customer_phone'] || _get(order, 'user.profile.contacts'),
  cart: getPayKeeperCart({
    vat: _get(order, 'company.vat'),
    items: order.items,
    delivery: order.delivery,
  }),
});

const renderPaykeeperPopup = (order, doAfterClose) => {
  const popupData = getDataForRender(order);
  if (app.config.isMobile) {
    const template = mobilePopup({data: popupData});
    const body = document.querySelector('body');
    body.insertAdjacentHTML('beforeEnd', template);
  } else {
    const $dialog = $(desktopPopup({data: popupData})).dialog({
      resizable: false,
      modal: true,
      draggable: false,
      dialogClass: 'pay-keeper-popup',
    });
    $dialog
      .siblings()
      .find('.ui-dialog-title')
      .html(app.i18n.payKeeperPopup.headerHtml);
  }
  _doAfterClose = doAfterClose;
  init();
};

export default renderPaykeeperPopup;
