var Handlebars = require("../../../../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"rl-item "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":23},"end":{"line":10,"column":60}}})) != null ? stack1 : "")
    + " js-rl-item\">\r\n      <a class=\"rl-link js-rl-link\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" data-region=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" data-url=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\">\r\n        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\r\n      </a>\r\n    </li>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "rl-item-checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input\r\n  class=\"search-region js-search-region\"\r\n  type=\"text\"\r\n  placeholder=\""
    + alias2(__default(require("../../../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"popupChoseRegion.searchMobile",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":15},"end":{"line":4,"column":55}}}))
    + "\"\r\n  data-direction=\"ru\"\r\n>\r\n<div class=\"search-clear js-search-clear\"></div>\r\n<ul class=\"region-list js-region-list\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":2},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "</ul>\r\n<div class=\"search-empty js-search-empty\">\r\n  "
    + alias2(__default(require("../../../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"popupChoseRegion.searchEmpty",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":2},"end":{"line":18,"column":41}}}))
    + "\r\n</div>\r\n";
},"useData":true});