var Handlebars = require("../../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"order-product-success-popup__contacts product-company-about__contacts\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"phone") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":10},"end":{"line":36,"column":17}}})) != null ? stack1 : "")
    + "          <div class=\"product-company-about__messengers product-company-about__messengers_without-indent\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"whatsapp") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":56,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"telegram") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":12},"end":{"line":73,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"product-company-about__communication-item product-company-about__communication-item_phone aui-icon aui-icon_phone aui-icon_phone_color_orange\">\n              <a\n                class=\"js-create-appeal-order js-conversion-event js-send-phone-analytics\"\n                data-company-id=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"company") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"\n                data-event-item=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"productId") : depth0), depth0))
    + "\"\n                data-event-type=\"appeal\"\n                data-target-action-type=\"phone_view\"\n                href=\"tel:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"phone") : depth0), depth0))
    + "\"\n              >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"phone") : depth0), depth0))
    + "</a>\n            </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <a\n                class=\"\n                  aui-icon aui-icon_whatsapp\n                  whatsapp-button whatsapp-button_contacts\n                  js-create-appeal-order js-send-whatsapp-analytics js-conversion-event\n                  _analytic-whatsapp-button-company-contacts\n                  product-company-about__messenger\n                  order-product-success-popup__messenger\n                \"\n                href=\"https://wa.me/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"whatsapp") : depth0)) != null ? lookupProperty(stack1,"phone") : stack1), depth0))
    + "?text="
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"companyAbout.defaultWhatsappMessage",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":48,"column":60},"end":{"line":48,"column":108}}}))
    + "\"\n                target=\"_blank\"\n                data-statistic-url=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"whatsapp") : depth0)) != null ? lookupProperty(stack1,"company_statistic_url") : stack1), depth0))
    + "\"\n                data-target-action-type=\"whatsapp_view\"\n                data-company-id=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"company") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"\n                data-event-type=\"whatsapp\"\n                data-event-item=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"productId") : depth0), depth0))
    + "\"\n              ></a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <a\n                class=\"\n                  aui-icon aui-icon_telegram\n                  js-create-appeal-order js-send-telegram-analytics js-conversion-event\n                  product-company-about__messenger\n                  order-product-success-popup__messenger\n                \"\n                href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"telegram") : depth0)) != null ? lookupProperty(stack1,"phone") : stack1), depth0))
    + "\"\n                target=\"_blank\"\n                data-statistic-url=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"telegram") : depth0)) != null ? lookupProperty(stack1,"company_statistic_url") : stack1), depth0))
    + "\"\n                data-target-action-type=\"telegram_view\"\n                data-company-id=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"company") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"\n                data-event-type=\"telegram\"\n                data-event-item=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"productId") : depth0), depth0))
    + "\"\n              ></a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"order-product-success-popup\">\n  <div class=\"order-product-success-popup__title-container\">\n    <h2 class=\"order-product-success-popup__title\">"
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"succesOrderPopup.header",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":51},"end":{"line":3,"column":85}}}))
    + "</h2>\n    <div class=\"order-product-success-popup__title-icon\"></div>\n  </div>\n  <div class=\"order-product-success-popup__content\">\n    <div class=\"order-product-success-popup__notice\">\n      <p>\n        Ваш <a class=\"aui-link\" href=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"order_url") : depth0), depth0))
    + "\" target=\"_blank\">Заказ №"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "</a> принят в обработку. Представитель компании свяжется с вами.\n      </p>\n      <p>\n        Вы можете отследить статус заказа в <a class=\"aui-link\" href=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"order_url") : depth0), depth0))
    + "\" target=\"_blank\">Личном кабинете</a>, в разделе «История покупок».\n      </p>\n    </div>\n  \n    <div class=\"order-product-success-popup__divider\">\n      <h4 class=\"order-product-success-popup__subtitle\">Контакты компании</h4>\n      <div class=\"order-product-success-popup__company-name product-company-info\">\n        <div class=\"product-company-info__rank aui-icon aui-icon_company-rank aui-icon_company-rank_"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"rank") : depth0), depth0))
    + "\"></div>\n        <p class=\"product-company-info__name\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"order") : depth0)) != null ? lookupProperty(stack1,"company") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</p>\n      </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isHasContacts") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":6},"end":{"line":76,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n    <div\n      class=\"js-order-success-popup-footer\"\n      data-additional-class=\"order-product-success-popup__divider\"\n    ></div>\n  </div>\n</div>\n";
},"useData":true});