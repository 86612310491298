var Handlebars = require("../../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label class=\"form-group contact-phone__label js-of-required\">\n  <span class=\"of-label label required\">"
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"contact_phone_label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":40},"end":{"line":2,"column":70}}}))
    + "</span>\n  <div class=\"text-field-wrapper\">\n    <input\n      type=\"tel\"\n      class=\"text-field js-contact-phone__field js-of-required-field js-of-customer_phone\"\n      value=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"phone") : depth0), depth0))
    + "\"\n      name=\"order[customer_phone]\"\n      data-phone-mask-options='{\"onlyDefaultCode\": \"true\", \"notRemoveCode\": \"true\"}'\n    />\n  </div>\n  <span\n    class=\"contact-phone__error error-block js-contact-phone__error\"\n    data-empty-error=\""
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"contact_phone_error",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":22},"end":{"line":14,"column":52}}}))
    + "\"\n    data-other-error=\""
    + alias2(__default(require("../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"contact_unknown_format_error",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":22},"end":{"line":15,"column":61}}}))
    + "\"\n  ></span>\n</label>\n";
},"useData":true});