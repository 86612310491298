// Утилиты для создания клиентских заказов
import {getCookie} from '@apress/apress-frontend-utils/utils/get-cookie/get-cookie';
import {modalDialog} from '@apress/apress-frontend-utils/utils/modal-dialog/modal-dialog.js';

app.modules.customerOrdersUtils = (function (self) {
  var _timeout, _boundClosePopup;

  function createAppealWithRecaptcha(data) {
    app.modules.recaptcha
      .getScript()
      .then(function () {
        return app.modules.recaptcha.getToken();
      })
      .then(function (token) {
        app.modules.userUtils
          .signUp({withSID: true, response_token: token})
          .then(
            app.modules.customerOrdersAPI.createAppealOrder.bind(null, data)
          );
      });
  }

  function _createAppealOrder(data) {
    if (!data.companyId) {
      return $.Deferred().reject();
    }

    if (app.config.isUserSigned) {
      return app.modules.customerOrdersAPI.createAppealOrder(data);
    }

    if (!data.disableRecaptcha && app.modules.recaptcha) {
      return createAppealWithRecaptcha(data);
    }

    app.modules.userUtils
      .signUp({withSID: true})
      .then(app.modules.customerOrdersAPI.createAppealOrder.bind(null, data));
  }

  function _registerWithSID() {
    var $$regUser = $.Deferred();

    $doc.trigger('register:authComponent', [
      $$regUser,
      {
        withSID: true,
        crossDomainAuth: app.config.crossDomainAuth,
      },
    ]);

    return $$regUser;
  }

  function _replaceText(text, paramOrParams) {
    if (Array.isArray(paramOrParams)) {
      paramOrParams.forEach(function (item) {
        text = text.replace(item.key, item.value);
      });
    } else {
      text = text.replace(paramOrParams.key, paramOrParams.value);
    }

    return text;
  }

  function _getPathTemplateByPlatform() {
    return 'cosmos_client/' + (app.config.isMobile ? 'mobile/' : '');
  }

  function _lockScroll(isModal) {
    isModal
      ? $('.js-body').addClass('lock-scroll lock-scroll_modal')
      : $('.js-body').addClass('lock-scroll');
  }

  function _unlockScroll(isModal) {
    isModal
      ? $('.js-body').removeClass('lock-scroll lock-scroll_modal')
      : $('.js-body').removeClass('lock-scroll');
  }

  //ToDo Убрать параметр isNative везде где он есть, при реализации последней задачи эпика https://jira.railsc.ru/browse/PC4-28404
  function _openPopup($dialog, isNative) {
    if (!isNative) {
      $dialog.dialog('open');
      return;
    }

    $dialog.open();
  }

  //ToDo Убрать параметр isNative везде где он есть, при реализации последней задачи эпика https://jira.railsc.ru/browse/PC4-28404
  function _closePopup($dialog, isNative) {
    if (!isNative) {
      $dialog.dialog('close');
      $win.off('resize.orderPopup');
      return;
    }
    $dialog.close();
  }

  //ToDo Убрать параметр isNative везде где он есть, при реализации последней задачи эпика https://jira.railsc.ru/browse/PC4-28404
  function _addPopupOpenedHistory($dialog, isNative) {
    _boundClosePopup = _closePopup.bind(null, $dialog, isNative);
    clearTimeout(_timeout);
    _timeout = setTimeout(function () {
      window.history.pushState('popup-open', null, '');
      window.addEventListener('popstate', _boundClosePopup);
    }, 150);
  }

  function _removePopupOpenedHistory() {
    if (window.history.state === 'popup-open') {
      window.history.back();
    }
    window.removeEventListener('popstate', _boundClosePopup);
  }

  function _getOrderPopupParams(customParams) {
    return $.extend(
      true,
      {
        modal: true,
        resizable: false,
        width: 768,
        height: 'auto',
        dialogClass:
          'dialog-default dialog-default_hide-close-button orders-popup',
      },
      customParams
    );
  }

  function _isLockedPopup(type) {
    return _isLockedOrderType(type) || app.config.ordersUpdatesLocked;
  }

  function _isLockedOrderType(type) {
    return (
      app.config.companyCommunicationShow &&
      app.config.companyCommunicationShow[type] === false
    );
  }

  function _isLockedRequestCompanyOrder(
    type,
    currentCompanyId,
    ordersRequestCompanyId
  ) {
    return (
      app.config.ordersUpdatesLocked ||
      (currentCompanyId === ordersRequestCompanyId && _isLockedOrderType(type))
    );
  }

  return $.extend(self, {
    createAppealOrder: _createAppealOrder,
    registerWithSID: _registerWithSID,
    replaceText: _replaceText,
    pathTemplateByPlatform: _getPathTemplateByPlatform,
    lockScroll: _lockScroll,
    unlockScroll: _unlockScroll,
    openPopup: _openPopup,
    closePopup: _closePopup,
    isLockedPopup: _isLockedPopup,
    isLockedRequestCompanyOrder: _isLockedRequestCompanyOrder,
    getOrderPopupParams: _getOrderPopupParams,
    addPopupOpenedHistory: _addPopupOpenedHistory,
    removePopupOpenedHistory: _removePopupOpenedHistory,
    getCookie,
    modalDialog,
  });
})(app.modules.customerOrdersUtils || {});
